import clsx from "clsx";
import React from "react";
import FormColumn2Wide from "./FormColumn2Wide";
import FormColumn3Wide from "./FormColumn3Wide";
import FormColumn4Wide from "./FormColumn4Wide";

type FormColumnProps = {
  className?: string;
  width?: 1 | 2 | 3 | 4;
};

const FormColumn: React.FC<React.PropsWithChildren<FormColumnProps>> = ({ children, className, width = 1 }) => {
  switch (width) {
    case 2:
      return <FormColumn2Wide className={className}>{children}</FormColumn2Wide>;
    case 3:
      return <FormColumn3Wide className={className}>{children}</FormColumn3Wide>;
    case 4:
      return <FormColumn4Wide className={className}>{children}</FormColumn4Wide>;
    case 1:
    default:
      return <div className={clsx("col-12", className)}>{children}</div>;
  }
};

export default FormColumn;
